<template>
    <div class="container">
        <header-view :showMenu="showMenu" :page="0" @change="changeMenu"></header-view>
        <swiper ref="mySwiper" class="swiper-wrap" :options="swiperOptions" @swiper="onSwiper"
                @slideChange="onSlideChange">
            <swiper-slide>
                <div class="swiper5" v-if="showVideoBg">
                    <swiper ref="mySwiper5" :options="swiperOptions5">
                        <swiper-slide v-for="(item,index) in bannerList" :key="index">
                            <img :src="item.imgUrl" alt="" @click.stop="goDetail(item)">
                        </swiper-slide>
                    </swiper>
                </div>
                <!-- <video v-else x5-video-player-type="h5" muted autoplay @ended="videoEnd"
                       src="../../assets/video/video.mp4"></video> -->
                <div class="portion flex-row" v-if="showVideoBg && $store.state.user.isLogin">
                    <div class="label">
                        <p class="ft-14">项目投资</p>
                    </div>
                    <div class="play-wrap flex-row" @click="toMovies">
                        <img src="../../assets/images/play_icon.png">
                    </div>
                </div>
            </swiper-slide>
            <!-- 新闻 -->
            <swiper-slide>
                <div class="swiper2 flex-col">
                    <div class="mask-left"></div>
                    <div class="mask-right"></div>
                    <swiper v-if="showSwiper2" ref="mySwiper2" :options="swiperOptions2">
                        <swiper-slide v-for="(item,index) in newsList" :key="index" :data-id="item.id">
                            <img :src="item.coverUrl" alt="">
                            <div class="text">
                                <div class="top ellipsis ft-22 ellipsis">{{ item.title }}</div>
                                <div class="bottom ellipsis">{{ item.viceTitle }}</div>
                            </div>
                        </swiper-slide>
                        <div class="swiper-pagination1" slot="pagination"></div>
                    </swiper>
                    <div class="more" @click="toNews">MORE</div>
                </div>
            </swiper-slide>
            <!-- 影视 -->
            <!-- <swiper-slide v-if="$store.state.user.isLogin">
                <div data-x class="swiper3 flex-col">
                    <swiper v-if="showSwiper3" ref="mySwiper3" :options="swiperOptions3">
                        <swiper-slide v-for="(item,index) in moviesList" :key="index" :data-id="item.id">
                            <div class="item">
                                <img :src="item.imgUrl" alt="">
                                <div class="text">
                                    <div class="top ellipsis ft-18">{{ item.name }}</div>
                                    <div class="bottom ellipsis">{{ item.information }}</div>
                                </div>
                            </div>
                        </swiper-slide>
                    </swiper>
                    <div class="swiper-button-prev">
                        <img src="../../assets/images/left-arrow.png" alt="">
                    </div>
                    <div class="swiper-button-next">
                        <img src="../../assets/images/right-arrow.png" alt="">
                    </div>
                    <div class="more" @click="toMovies">MORE</div>
                </div>
            </swiper-slide> -->
            <!-- 艺人 -->
            <swiper-slide>
                <div class="thumbs-container">
                    <div class="swiper4">
                        <swiper ref="mySwiper4" :options="swiperOptions4">
                            <swiper-slide v-for="(item,index) in actorList" :key="index">
                                <div class="acrorInfo">
                                    <h1 class="ft-32">{{ item.letter }}</h1>
                                    <p class="ft-14">{{ item.name }}</p>
                                </div>
                                <img :src="item.imgUrl" alt="">
                            </swiper-slide>
                        </swiper>
                    </div>
                    <div class="swiper-thumbs">
                        <swiper ref="mySwiperThumbs" :options="swiperOptionThumbs">
                            <swiper-slide v-for="(item,index) in actorList" :key="index">
                                <img :src="item.imgUrl" alt="">
                            </swiper-slide>
                        </swiper>
                        <div class="swiper-button-prev1">
                            <img src="../../assets/images/left-arrow-small.png" alt="">
                        </div>
                        <div class="swiper-button-next1">
                            <img src="../../assets/images/right-arrow-small.png" alt="">
                        </div>
                    </div>
                    <!--                    <div class="text-wrapper">-->
                    <!--                        <p>WANG QING</p>-->
                    <!--                        <p class="ft-14">王青-新人演员/</p>-->
                    <!--                    </div>-->
                </div>
            </swiper-slide>
            <!-- 免责声明 -->
            <swiper-slide>
                <div class="thumbs-container custom_bg">
                    <img src="@/assets/images/disclaimer.jpg" lazy  style="height: 80vh"  />
                </div>
            </swiper-slide>
            
            <!-- 合作伙伴 -->
            <swiper-slide>
                <div class="partner-wrap" id="partner">
                    <div class="partner-box flex-row">
                        <div class="partner flex-row">
                            <div class="item flex-row" v-for="(item,index) in partnerList" :key="index">
                                <a :href="item.jumpUrl" target="_blank">
                                    <img :src="item.imgUrl"/>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="footer flex-row">
                        <div class="footer-wrap">
                            <div class="top">
                                <div class="w80 flex-row">
                                    <div class="item1 flex-row">
                                        <div class="title ft-20">联系我们</div>
                                        <small>Contact Us</small>
                                    </div>
                                    <div class="item2 flex-row">
                                        <p class="ft-14">地址：厦门市集美区杏林湾营运中心11号楼1201</p>
                                        <p class="ft-14">邮箱：2545817883@qq.com</p>
                                    </div>
                                    <div class="item3 flex-row">
                                        <p class="ft-14">服务时间：周一至周五9:00~20:00 </p>
                                        <p class="ft-14">邮编：361022</p>
                                    </div>
                                    <div class="item4 flex-row">
                                        <p class="ft-14">联系电话</p>
                                        <p class="ft-14">15280605328</p>
                                    </div>
                                </div>
                            </div>
                            <div class="bottom">
                                <div class="w80 flex-row">
                                    <p class="ft-12">浩亿影业 : www.haoyifilmgroup.com 版权所有 | <a
                                        href="https://beian.miit.gov.cn" target="_blank">闽ICP备18025946号-1</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </swiper-slide>
            <div v-show="isShowPagination" class="swiper-pagination pagination-wrap" slot="pagination"></div>
        </swiper>
    </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import headerView from '@/components/header/headerView.vue'
import 'swiper/swiper-bundle.min.css'
import {rotationList, newsList, actorList, partnerList, moviesList} from '@/api/api.js'
import SwiperCore, {
    Autoplay,
    Navigation,
    Pagination,
    Mousewheel,
    EffectCoverflow,
    Thumbs,
    Controller,
    EffectFade
} from 'swiper';

SwiperCore.use([Navigation, Autoplay, Mousewheel, Pagination, EffectCoverflow, Thumbs, Controller, EffectFade]);
let vm = null
export default {
    components: {
        Swiper,
        SwiperSlide,
        headerView
    },
    data() {
        return {
            showVideoBg: true,
            isShowPagination: false,
            showMenu: false,
            pageNo: 1,
            pageSize: 100,
            swiperOptions: {
                speed: 500,
                simulateTouch: false,
                grabCursor: true,
                centeredSlides: true,
                direction: 'vertical',
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    type: 'bullets',
                    renderBullet(index, className) {
                        let text;
						if (vm.$store.state.user.isLogin) {
							switch (index) {
								case 0:
									text = '首页';
									break;
								case 1:
									text = '新闻动态';
									break;
								// case 2:
								// 	text = '影视作品';
								// 	break;
								case 2:
									text = '合作艺人';
									break;
                                case 3:
									text = '免责声明';
									break;
								case 4:
									text = '合作伙伴';
									break;
							}
						} else {
							switch (index) {
								case 0:
									text = '首页';
									break;
								case 1:
									text = '新闻动态';
									break;
								case 2:
									text = '合作艺人';
									break;
                                case 3:
									text = '免责声明';
									break;
								case 4:
									text = '合作伙伴';
									break;
							}
						}
                        
                        return '<div class="flex-col ' + className + '"><span></span><small><em>' + text + '</em></small></div>';
                    },
                },
                mousewheel: true,
            },
            swiperOptions2: {
                speed: 1000,
                loop: true,
                delay: 5000,
                autoplay: false,
                initialSlide: 0,
                slidesPerView: 2,
                spaceBetween: 150,
                centeredSlides: true,
                effect: 'coverflow',
                coverflowEffect: {
                    rotate: 0,
                    depth: 150,
                    slideShadows: false
                },
                pagination: {
                    el: '.swiper-pagination1',
                    clickable: true,
                    renderBullet(index, className) {
                        return `<span class="${className} swiper-pagination-bullet-custom">${index + 1}</span>`
                    },
                },
                on: {
                    click: (v) => {
                        let id = v.clickedSlide.getAttribute('data-id')
                        vm.$router.push({path: '/newsDetail', query: {id: id}})
                    }
                }
            },
            swiperOptions3: {
                speed: 1000,
                loop: true,
                delay: 5000,
                autoplay: false,
                initialSlide: 0,
                slidesPerView: 2,
                spaceBetween: 100,
                centeredSlides: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                on: {
                    click: (v) => {
                        let id = v.clickedSlide.getAttribute('data-id')
                        vm.$router.push({path: '/moviesDetail', query: {id: id}})
                    }
                }
            },
            swiperOptions4: {
                autoplay: false,
                speed: 1000,
                slidesPerView: 1,
                loopedSlides: 5,
                centeredSlides: true,
                effect: 'fade',
                fadeEffect: {
                    crossFade: true
                },
            },
            swiperOptionThumbs: {
                speed: 1000,
                slidesPerView: 5,
                loopedSlides: 5,
                spaceBetween: 20,
                watchSlidesProgress: true,
                slideToClickedSlide: true,
                centeredSlides: true,
                watchSlidesVisibility: true,
                navigation: {
                    nextEl: '.swiper-button-next1',
                    prevEl: '.swiper-button-prev1'
                },
            },
            swiperOptions5: {
                speed: 700,
                loop: true,
                autoplay: true
            },
            newsList: [],//新闻轮播
            actorList: [],//艺人
            partnerList: [],//合作伙伴
            moviesList: [],//影视作品
            bannerList: [],
        }
    },
    computed: {
        showSwiper2() {
            return this.newsList.length
        },
        showSwiper3() {
            return this.moviesList.length
        }
    },
    created() {
        vm = this
        this.getRotationList()
        this.getList()
        this.getActorList()
        this.getPartnerList()
        this.getMoviesList()
    },
    mounted() {
        this.$nextTick(() => {
            const mySwiper4 = this.$refs.mySwiper4.$swiper
            const swiperThumbs = this.$refs.mySwiperThumbs.$swiper
            mySwiper4.controller.control = swiperThumbs
            swiperThumbs.controller.control = mySwiper4
        })
    },
    methods: {
        videoEnd() {
            this.showVideoBg = true
        },
        toNews() {
            this.$store.state.user.cacheList.push('news')
            this.$router.push({path: '/news', query: {scrollMain: 1}})
        },
        toMovies() {
            this.$store.state.user.cacheList.push('movies')
            this.$router.push({path: '/moviesWorks', query: {scrollMain: 1}})
        },
        onSwiper(swiper) {
            console.log(swiper)
        },
        onSlideChange(swiper) {
            this.isShowPagination = swiper.activeIndex !== 0
            // if(swiper.activeIndex > 0 && swiper.activeIndex < 4){
            //     let specialTags = document.querySelectorAll('[data-x]')
            //     specialTags[swiper.activeIndex - 1].classList.remove('offset')
            // }
            let partner = document.getElementById('partner')
            if (swiper.activeIndex === 4) {
                partner.classList.add('on')
            } else {
                partner.classList.remove('on')
            }
            this.$refs.mySwiper4.$swiper.autoplay.stop()
            this.showMenu = swiper.activeIndex !== 0
            switch (swiper.activeIndex) {
                case 1:
                    this.$refs.mySwiper2.$swiper.autoplay.start()
                    break;
                case 3:
                    setTimeout(() => {
                        this.$refs.mySwiper4.$swiper.autoplay.start()
                    }, 100)
                    break;
            }
        },
        // slideChangeTransitionEnd(swiper) {

        // },
        changeMenu(bool) {
            this.showMenu = !bool
        },
        getRotationList() {
            rotationList({isLogin: this.$store.state.user.isLogin ? 1 : 0}).then(res => {
                if (res.success) {
                    this.bannerList = res.result
                }
            })
        },
        goDetail(item) {
            if (!item.objectId) return
            if (item.type === 'news') {
                this.$router.push({path: '/newsDetail', query: {id: item.objectId}})
            }
            if (item.type === 'movies') {
                this.$router.push({path: '/moviesDetail', query: {id: item.objectId}})
            }
        },
        // 获取新闻轮播
        getList() {
            let data = {
                pageNo: 1,
                pageSize: 8,
                type: '',
				isLogin: this.$store.state.user.isLogin ? 1 : 0
            }
            newsList(data).then(res => {
                if (res.success) {
                    this.newsList = res.result.records
                }
            })
        },
        getActorList() {
            actorList({pageNo: 1, pageSize: 99, status: 'on'}).then(res => {
                if (res.success) {
                    this.actorList = res.result.records
                }
            })
        },
        getPartnerList() {
            partnerList({pageNo: 1, pageSize: 99}).then(res => {
                if (res.success) {
                    this.partnerList = res.result.records
                }
            })
        },
        getMoviesList() {
            moviesList({
                pageNo: 1,
                pageSize: 8,
                startStatus: '',
                type: ''
            }).then(res => {
                if (res.success) {
                    this.moviesList = res.result.records
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.w80 {
    width: 80%;
    margin: 0 auto;
}

.container {
    height: 100vh;
}

/deep/ .swiper-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    color: #fff;
    --swiper-pagination-color: #fff;

    img.video-bg {
        width: 100%;
        object-fit: cover;
    }

    video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
    }

    &.swiper-wrap {
        .pagination-wrap {
            right: 20px;

            .swiper-pagination-bullet {
                width: 30px;
                height: initial;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-end;
                margin: 10px 0 0 0;
                border-radius: initial;
                background: none;
                opacity: 1;

                span {
                    display: inline-block;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    line-height: 6px;
                    text-align: center;
                    border: 1px solid #fff;
                    transition: all 0.3s ease-in-out;
                    -webkit-transition: all 0.3s ease-in-out;
                    -moz-transition: all 0.3s ease-in-out;
                    -o-transition: all 0.3s ease-in-out;
                    -ms-transition: all 0.3s ease-in-out;
                    box-sizing: border-box;
                    background-color: transparent;
                }

                small {
                    display: inline-block;
                    height: 0;
                    white-space: nowrap;
                    position: relative;

                    em {
                        text-transform: capitalize;
                        color: #fff;
                        position: absolute;
                        right: 0;
                        top: 0;
                        white-space: nowrap;
                        line-height: 42px;
                        text-align: right;
                        transform: translate3d(-12px, -12px, 0);
                        -webkit-transform: translate3d(-12px, -12px, 0);
                        -moz-transform: translate3d(-12px, -12px, 0);
                        -o-transform: translate3d(-12px, -12px, 0);
                        -ms-transform: translate3d(-12px, -12px, 0);
                        opacity: 0;
                        transition: all 0.5s ease-in-out;
                        -webkit-transition: all 0.5s ease-in-out;
                        -moz-transition: all 0.5s ease-in-out;
                        -o-transition: all 0.5s ease-in-out;
                        -ms-transition: all 0.5s ease-in-out;
                        transition: transform .7s cubic-bezier(0.3, 0, 0.24, 1), opacity 0.5s ease;
                    }
                }

                &.swiper-pagination-bullet-active {
                    background: none;

                    span {
                        background-color: #FFF;
                        opacity: 1;
                    }

                    small {
                        height: 40px;

                        em {
                            opacity: 1;
                            transform: translate3d(0px, 0, 0);
                            -webkit-transform: translate3d(0px, 0, 0);
                            -moz-transform: translate3d(0px, 0, 0);
                            -o-transform: translate3d(0px, 0, 0);
                            -ms-transform: translate3d(0px, 0, 0);
                        }
                    }
                }
            }
        }
    }

    .portion {
        z-index: 1;
        position: absolute;
        bottom: 58px;
        right: 55px;

        .label {
            margin-right: 40px;

            &::after {
                position: absolute;
                top: 50%;
                right: 24%;
                content: "";
                width: 38px;
                height: 1px;
                background-color: #979797;
            }
        }

        .play-wrap {
            width: 50px;
            height: 50px;
            position: relative;
            cursor: pointer;

            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-image: url(../../assets/images/play_load.png);
                background-size: 100%;
                animation: roll 2s linear infinite;
            }
        }
    }
}

.swiper2,
.swiper3 {
    position: relative;
    width: 100vw;
    height: 100vh;
    background: url("../../assets/images/bg1.png");
    justify-content: space-between;
    background-size: 100% 100%;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .swiper-container {
        width: 100%;
        height: auto;
    }

    .more {
        position: absolute;
        bottom: 0;
        left: 50%;
        bottom: 8vh;
        transform: translateX(-50%);
        cursor: pointer;
        padding: 12px 32px;
        font-size: 16px;
        color: #6B6B6B;
        border: 1px solid #6B6B6B;
        transition: all .3s linear;

        &:hover {
            background: #6B6B6B;
            color: #131418;
        }
    }
}

.swiper2 {
    position: relative;

    .swiper-container {
        margin-top: 21vh;
        height: 54vh;

        .swiper-slide {
            cursor: pointer;

            .text {
                visibility: hidden;
                opacity: 0;
                width: 100%;
                padding: 14px 12px;
                position: absolute;
                bottom: 0;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0.57) 100%);
                text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
                transition: all .3s linear;

                .top {
                    width: 90%;
                    margin-bottom: 2px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-weight: bold;
                }

                .bottom {
                    width: 98%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 14px;
                }
            }

            &.swiper-slide-active {
                .text {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }

    .mask-left, .mask-right {
        height: 100vh;
        position: absolute;
        width: 20%;
    }

    .mask-left {
        z-index: 100;
        left: 0;
        background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
    }

    .mask-right {
        z-index: 100;
        right: 0;
        background: linear-gradient(270deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
    }
}

.swiper3 {
    background: url("../../assets/images/bg2.png");
    padding: 20vh 0 8vh;

    .swiper-container {
        width: 84%;
        height: 40vh;
		margin-top: 10vh;

        .swiper-wrapper {
            .swiper-slide {
                position: relative;
                overflow: hidden;
                cursor: pointer;

                &::after {
                    transition: all .3s linear;
                    position: absolute;
                    left: 0;
                    top: 0;
                    content: '';
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(
                            180deg, rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0.57) 100%);
                    opacity: 0;
                    visibility: hidden;
                }

                .item {
                    width: 100%;
                    height: 100%;

                    img {
                        transition: all .3s linear;
                        transform: scale(1);
                    }

                    .text {
                        width: 100%;
                        padding: 14px 12px;
                        position: absolute;
                        bottom: 0;
                        z-index: 5;
                        background: linear-gradient(180deg, rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0.57) 100%);
                        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
                        transition: all .3s linear;

                        .top {
                            width: 90%;
                            margin-bottom: 2px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            font-weight: bold;
                        }

                        .bottom {
                            width: 98%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            font-size: 14px;
                        }
                    }
                }

                &:hover {
                    &::after {
                        visibility: visible;
                        opacity: 1;
                    }

                    img {
                        transform: scale(1.06);
                    }
                }
            }
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        z-index: 1;

        &:after {
            content: none;
        }
    }

    .swiper-button-prev {
        left: 5%;
    }

    .swiper-button-next {
        right: 5%;
    }
}

.thumbs-container {
    position: relative;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(12, 12, 12, 0.11) 50%, #020203 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &.custom_bg {
        background: url("../../assets/images/bg1.png") !important;
    }
    .swiper4 {
        width: 40%;
        height: 100%;

        .swiper-container {
            height: 100%;
            overflow: initial !important;

            .swiper-wrapper {
                .swiper-slide {
                    img {
                        width: 100%;
                        max-height: 100%;
                        object-fit: contain;
                        transform: scale(1.08);
                    }

                    .acrorInfo {
                        position: absolute;
                        right: -4vw;
                        top: 30vh;
                        z-index: 5;

                        h1, p {
                            letter-spacing: 6px;
                            margin: 1vh 0;
                        }
                    }

                    &.swiper-slide-active {
                        // position: relative;
                        &::after {
                            position: absolute;
                            left: 0;
                            top: 0;
                            content: '';
                            width: 100%;
                            height: 30%;
                            background: linear-gradient(180deg, rgb(20, 20, 20) 0%, rgba(0, 0, 0, 0) 100%);
                        }

                        img {
                            transition: all 1s linear;
                            transform: scale(.8);
                        }
                    }
                }
            }
        }
    }

    /deep/ .swiper-thumbs {
        width: 100%;
        height: auto;
        position: absolute;
        bottom: 14vh;

        .swiper-button-prev1, .swiper-button-next1 {
            cursor: pointer;
            position: absolute;
            top: 40%;

            &:after {
                content: none;
            }
        }

        .swiper-container {
            width: 60%;
            height: 20vh;

            .swiper-wrapper {
                align-items: center;

                .swiper-slide {
                    position: relative;
                    width: 25%;
                    height: 100%;
                    cursor: pointer;
                    overflow: hidden;
                    transition: all 0.3s ease-in-out;
                    -webkit-transition: all 0.3s ease-in-out;
                    -moz-transition: all 0.3s ease-in-out;
                    -o-transition: all 0.3s ease-in-out;
                    -ms-transition: all 0.3s ease-in-out;
                    // &:hover{
                    // 	height: 12vh;
                    // }
                    &::after {
                        content: '';
                        width: 100%;
                        height: 100%;
                        // background-color: rgba(0,0,0,.5);
                        position: absolute;
                        left: 0;
                        top: 0;
                    }

                    img {
                        object-fit: contain;
                        width: 100%;
                        height: 100%;
                    }

                    &.swiper-slide-active {
                        opacity: 1;

                        &::after {
                            content: '';
                            width: 100%;
                            height: 100%;
                            background: none;
                        }
                    }
                }
            }
        }

        .swiper-button-prev1 {
            left: 16%;
        }

        .swiper-button-next1 {
            right: 16%;
        }
    }

    //.text-wrapper{
    //    color: #FFFFFE;
    //    letter-spacing: 12px;
    //    z-index: 1;
    //    position: absolute;
    //    top: 38vh;
    //    right: 30%;
    //    transform: translateX(80%);
    //    font-size: 32px;
    //    p{
    //        margin-bottom: 4px;
    //    }
    //}
}

.swiper5 {
    &::after {
        z-index: 1;
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        width: 100%;
        height: 30%;
        background: linear-gradient(180deg, rgb(20, 20, 20) 0%, rgba(0, 0, 0, 0) 100%);
    }

    /deep/ .swiper-slide {
        width: 100%;
        height: 100vh;

        img {
            width: 100%;
            object-fit: cover;
        }
    }
}

// 合作伙伴
.partner-wrap {
    height: 100%;

    &.on {
        .item, .item1, .item2, .item3, .item4, .bottom .w80 p {
            visibility: visible;
            -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
            animation-timing-function: cubic-bezier(0.3, 0, 0.24, 1);
            animation-name: fadeInUpSmall;
            -webkit-animation-name: fadeInUpSmall;
        }
    }

    .partner-box {
        height: calc(100% - 22vh);
        padding-top: 14vh;
        background: url(../../assets/images/parallax.png);
        background-size: 100% 100%;

        .partner {
            width: 60vw;
            //height: 12vw;
            margin: 0 auto;
            flex-wrap: wrap;

            .item {
                width: 12vw;
                height: 6vw;
                margin-right: 2vw;
                margin-bottom: 2vw;
                background: rgba(255, 255, 255, .1);

                img {
                    display: block;
                    width: 90%;
                    margin: 0 auto;
                }

                &:nth-child(4n) {
                    margin-right: 0;
                }

                &:nth-child(1), &:nth-child(5) {
                    animation-delay: .2s;
                }

                &:nth-child(2), &:nth-child(6) {
                    animation-delay: .4s;
                }

                &:nth-child(3), &:nth-child(7) {
                    animation-delay: .6s;
                }

                &:nth-child(4), &:nth-child(8) {
                    animation-delay: .8s;
                }
            }
        }
    }

    .footer {
        width: 100%;
        height: 22vh;
        background: #1D1D1D;

        .footer-wrap {
            width: 100%;
            height: 100%;
            margin: 0 auto;

            .top {
                width: 100%;
                height: calc(100% - 8vh);
                padding: 3vh 0;

                .w80 {
                    height: 100%;

                    .flex-row {
                        align-items: flex-start;
                        flex-grow: 1;
                        height: 100%;
                        flex-direction: column;
                        color: #7A7A7A;
                        animation-delay: .25s;

                        p {
                            margin-top: 1vh;
                        }

                        &.item2,
                        &.item3 {
                            flex-grow: 2;
                        }

                        &.item2 {
                            animation-delay: .5s;
                        }

                        &.item3 {
                            animation-delay: .75s;
                        }

                        &.item4 {
                            animation-delay: 1s;
                        }

                        .title {
                            color: #FFF;
                        }
                    }
                }
            }

            .bottom {
                position: relative;
                width: 100%;
                height: 8vh;

                &::before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    content: '';
                    width: 100%;
                    height: 1px;
                    background-color: #3F4D6C;
                    opacity: 0.2477;
                }

                .w80 {
                    height: 100%;
                    justify-content: flex-start;

                    p {
                        color: #6B6B6B;
                        animation-delay: .8s;

                        a {
                            color: #6B6B6B;
                        }
                    }
                }
            }
        }
    }
}

// [data-x]{
//     transform: translateY(0);
//     transition: all 1.2s;
// }
// [data-x].offset{
//     transform: translateY(200px);
//     opacity: 0;
// }
@media (max-width: 1440px) {
    .swiper2, .swiper3 {
        .swiper-slide {
            height: 90%;
        }

        .more {
            padding: 8px 20px;
            font-size: 12px;
        }
    }
    .swiper4 {
        width: 38%;
    }
    .swiper5 {
        .swiper-container {
            height: calc(100vh - 1px);
        }

        /deep/ .swiper-slide {
            img {
                width: 100%;
                object-fit: cover;
            }
        }
    }
}
</style>
